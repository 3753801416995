import React from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";

function NotFound() {
  const history = useHistory();
  return (
    <Result
      status="404"
      title="404"
      subTitle="抱歉，所訪問內容不存在或已刪除"
      extra={
        <Button type="primary" onClick={() => history.push("/")}>
          返回首頁
        </Button>
      }
    />
  );
}

export default NotFound;
