import React from "react";
import loadable from "@loadable/component";
import { Spin } from "antd";

const fallback = (
  <div style={{ padding: "50px", textAlign: "center" }}>
    <Spin />
  </div>
);
const Home = loadable(() => import("containers/Home/Home"), {
  fallback,
});
const Login = loadable(() => import("containers/Auth/Login"), {
  fallback,
});
const Logout = loadable(() => import("containers/Auth/Logout"), {
  fallback,
});
const MembersManage = loadable(() => import("containers/Members/Manage"), {
  fallback,
});
const NewsManage = loadable(() => import("containers/News/Manage"), {
  fallback,
});
const ActivitiesManage = loadable(
  () => import("containers/Activities/Manage"),
  {
    fallback,
  }
);
const ActivitiesEdit = loadable(() => import("containers/Activities/Edit"), {
  fallback,
});
const ActivitiesNameList = loadable(
  () => import("containers/Activities/NameList"),
  {
    fallback,
  }
);

const ROUTES = [
  {
    // 首頁
    path: "/",
    component: Home,
  },
  {
    // 登錄
    path: "/login",
    component: Login,
  },
  {
    // 登出
    path: "/logout",
    component: Logout,
  },
  {
    // 會員管理
    path: "/members/manage",
    component: MembersManage,
  },
  {
    // 新聞管理
    path: "/news/manage",
    component: NewsManage,
  },
  {
    // 活動管理
    path: "/activities/manage",
    component: ActivitiesManage,
  },
  {
    // 新建活動
    path: "/activities/create",
    component: ActivitiesEdit,
  },
  {
    // 編輯活動
    path: "/activities/:id/edit",
    component: ActivitiesEdit,
  },
  {
    // 活動名單
    path: "/activities/:id/name-list",
    component: ActivitiesNameList,
  },
];

export { ROUTES };
