import React from "react";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  UserOutlined,
  FileTextOutlined,
  CalendarOutlined,
} from "@ant-design/icons";

function Menus() {
  const location = useLocation();
  const match = location.pathname.match(/^\/([^/]+)/);
  return (
    <Menu
      selectedKeys={match ? [match[1]] : []}
      mode="horizontal"
      theme="light"
    >
      <Menu.Item key="members" icon={<UserOutlined />}>
        <Link to="/members/manage">會員管理</Link>
      </Menu.Item>
      <Menu.Item key="news" icon={<FileTextOutlined />}>
        <Link to="/news/manage">新聞管理</Link>
      </Menu.Item>
      <Menu.Item key="activities" icon={<CalendarOutlined />}>
        <Link to="/activities/manage">活動管理</Link>
      </Menu.Item>
    </Menu>
  );
}

export default Menus;
