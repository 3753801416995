import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "antd";

const LoginInfo: FC = () => {
  const history = useHistory();
  const login = () => {
    history.push("/login");
  };
  const logout = () => {
    history.push("/logout");
  };
  return (
    <div className="actions">
      <Button type="link" onClick={login}>
        登錄
      </Button>
      <Button type="link" onClick={logout}>
        登出
      </Button>
    </div>
  );
};

export default LoginInfo;
