import React, { FC } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { Layout } from "antd";
import { ROUTES } from "configs/routes";
import NotFound from "containers/Error/NotFound";
import Menus from "./Menus";
import "./App.sass";
import LoginInfo from "./LoginInfo";

const { Header, Content, Footer } = Layout;

const routes = [...ROUTES];

const App: FC = () => {
  return (
    <Router>
      <Layout className="App">
        <Header>
          <Link className="brand" to="/">
            鄉親鄉情互助平台
          </Link>
          <Menus />
          <LoginInfo />
        </Header>
        <Content>
          <Switch>
            {routes.map((route, idx) => (
              <Route
                exact
                key={idx}
                path={route.path}
                component={route.component}
              />
            ))}
            <Route path="*" component={NotFound} />
          </Switch>
        </Content>
        <Footer>
          &copy; 2020 香港晉江社團總會有限公司
          <br />
          THE FEDERATION OF JINJIANG HONG KONG ASSOCIATIONS LIMITED
        </Footer>
      </Layout>
    </Router>
  );
};

export default App;
